import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Container from '@material-ui/core/Container';
import Layout from 'components/layout';
import ImageHero from 'components/hero/imagehero';
import SmallImageHero from 'components/hero/SmallImageHero';
import {
  TitleCaptionText,
  TextLeftImageRight,
  InlineImageGrid,
  LargeImageBlock,
  ImageRightTextLeft,
  FounderQuote,
  TitleCaptionTwoColumnText,
  V2TextLeftImageRight,
  TeamMembers,
  WidescreenImageBlock,
  ImageSlider,
  TwoColumnText,
  FourImageGrid,
  Testimonials,
  Offers,
  Articles,
  Careers,
  Video,
  IframeEmbed,
  HelpToBuy,
  Image,
  UrlIframe,
  HousingSlideshow,
} from 'components/slices';
import Spacer from 'components/spacer';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';

function Page(props) {
  const doc = props.data.prismic.allStandard_pages.edges.slice(0, 1).pop();
  if (!doc) return null;
  return (
    <Layout>
      {doc.node.metadata && doc.node.metadata[0] && (
        <Helmet>
          {doc.node.metadata[0].title && doc.node.metadata[0].title[0] && (
            <title>{doc.node.metadata[0].title[0].text}</title>
          )}
          {doc.node.metadata[0].description &&
            doc.node.metadata[0].description[0] && (
              <Helmet>
                <meta
                  name="description"
                  content={doc.node.metadata[0].description[0].text}
                />
                <meta
                  property="og:description"
                  content={doc.node.metadata[0].description[0].text}
                />
                <meta
                  name="twitter:description"
                  content={doc.node.metadata[0].description[0].text}
                />
              </Helmet>
            )}
          {doc.node.metadata[0].keywords &&
            doc.node.metadata[0].keywords[0] && (
              <meta
                name="keywords"
                content={doc.node.metadata[0].keywords[0].text}
              />
            )}
        </Helmet>
      )}
      {!doc.node.header_hero_type && (
        <ImageHero
          data={doc.node.banner_image ? doc.node.banner_image : ''}
          banner_image_tablet={
            doc.node.banner_image_tablet ? doc.node.banner_image_tablet : ''
          }
          banner_image_mobile={
            doc.node.banner_image_mobile ? doc.node.banner_image_mobile : ''
          }
          title={doc.node.title[0].text}
          logo={doc.node.show_hayfield_logo_in_banner}
          email={
            doc.node.banner_bottom_bar && doc.node.banner_bottom_bar[0].email
              ? doc.node.banner_bottom_bar[0].email[0].text
              : ''
          }
          telephone={
            doc.node.banner_bottom_bar &&
            doc.node.banner_bottom_bar[0].telephone
              ? doc.node.banner_bottom_bar[0].telephone[0].text
              : ''
          }
          opening_hours={
            doc.node.banner_bottom_bar &&
            doc.node.banner_bottom_bar[0].opening_hours
              ? doc.node.banner_bottom_bar[0].opening_hours[0].text
              : ''
          }
          external_link={
            doc.node.banner_bottom_bar && doc.node.banner_bottom_bar[0].link
              ? doc.node.banner_bottom_bar[0].link.url
              : ''
          }
          contactbar={
            doc.node.banner_bottom_bar &&
            doc.node.banner_bottom_bar[0].show_info_bar_in_banner === true
              ? true
              : false
          }
        />
      )}
      {doc.node.header_hero_type && <SmallImageHero />}
      {doc.node.body && (
        <React.Fragment>
          {doc.node.body.map((item, i) => {
            switch (item.type) {
              case 'title__caption__text':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <Container maxWidth="xl">
                        <TitleCaptionText data={item.primary} />
                      </Container>
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'text_left_image_right':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <TextLeftImageRight data={item.primary} />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'inline_image_grid':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <InlineImageGrid
                        primary={item.primary}
                        data={item.fields}
                      />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'large_image_block':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <LargeImageBlock data={item.primary} />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'image_left_text_right':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <ImageRightTextLeft
                        primary={item.primary}
                        fields={item.fields}
                      />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'founder_quote':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <FounderQuote data={item.primary} />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'title__caption__two_column_text':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <Container maxWidth="xl">
                        <TitleCaptionTwoColumnText data={item.primary} />
                      </Container>
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'v2_text_left__image_right':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <V2TextLeftImageRight data={item.primary} />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'team_members':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <TeamMembers data={item.primary} fields={item.fields} />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'widescreen_image_block':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <WidescreenImageBlock
                        data={item.primary}
                        fields={item.fields}
                      />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'image_slideshow':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <Container maxWidth="xl">
                        <ImageSlider data={item.fields} />
                      </Container>
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'two_column_text':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <TwoColumnText data={item.primary} />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case '4_image_grid':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <FourImageGrid data={item.primary} />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'testimonials':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <Testimonials data={item.primary} fields={item.fields} />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'offers':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <Offers data={item.fields} />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'articles':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <Articles data={item.fields} />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'career_opportunities_':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <Careers data={item.primary} fields={item.fields} />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'video':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <Video data={item.primary} />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'iframe_embed':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <IframeEmbed data={item.primary} />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'help_to_buy_calculator':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <HelpToBuy data={item.primary} />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'image':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <Image data={item.primary} />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'url_to_iframe':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <UrlIframe data={item.primary} />
                    </LazyLoadComponent>
                  </Spacer>
                );
              case 'housing_slideshow':
                return (
                  <Spacer key={i}>
                    <LazyLoadComponent>
                      <HousingSlideshow data={item.fields} />
                    </LazyLoadComponent>
                  </Spacer>
                );
              default:
                return null;
            }
          })}
        </React.Fragment>
      )}
    </Layout>
  );
}

export const query = graphql`
  query StandardTypePageQuery($uid: String) {
    prismic {
      allStandard_pages(uid: $uid) {
        edges {
          node {
            _meta {
              id
              uid
              type
            }
            metadata {
              title
              keywords
              description
            }
            header_hero_type
            title
            banner_image
            banner_image_tablet
            banner_image_mobile
            show_hayfield_logo_in_banner
            banner_caption
            banner_bottom_bar {
              email
              telephone
              show_info_bar_in_banner
              opening_hours
              link_text
              link {
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
            }
            body {
              ... on PRISMIC_Standard_pageBodyTitle__caption__text {
                type
                label
                primary {
                  show_hayfield_logo
                  title
                  caption
                  text
                }
              }
              ... on PRISMIC_Standard_pageBodyText_left_image_right {
                type
                label
                primary {
                  caption
                  text
                  title
                  align_text
                  image
                  link {
                    ... on PRISMIC_Standard_page {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Home_type {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Contact_page {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Article {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Meet_the_team {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Homepage {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Basic_page {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Development {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_Standard_pageBodyInline_image_grid {
                type
                label
                primary {
                  title
                }
                fields {
                  image
                }
              }
              ... on PRISMIC_Standard_pageBodyLarge_image_block {
                type
                label
                primary {
                  link_title
                  show_logo_ident
                  show_logo_text
                  show_social_icons
                  title
                  image
                  mobile_image
                  link {
                    ... on PRISMIC_Contact_page {
                      _meta {
                        uid
                        type
                        id
                      }
                    }
                    ... on PRISMIC_Article {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Standard_page {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Meet_the_team {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Homepage {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Basic_page {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Development {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Search_page {
                      _linkType
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_Standard_pageBodyImage_left_text_right {
                type
                label
                primary {
                  image_title
                  image_link_title
                  image_link {
                    ... on PRISMIC_Standard_page {
                      title
                      _meta {
                        uid
                        type
                        id
                      }
                    }
                  }
                  image
                  title
                  caption
                  text
                }
                fields {
                  inline_images
                }
              }
              ... on PRISMIC_Standard_pageBodyFounder_quote {
                type
                label
                primary {
                  author
                  job_title
                  image
                  mobile_image
                  signature_image
                  text
                  title
                }
              }
              ... on PRISMIC_Standard_pageBodyTitle__caption__two_column_text {
                type
                label
                primary {
                  show_hayfield_logo
                  title
                  caption
                  text_left_column
                  text_right_column
                }
              }
              ... on PRISMIC_Standard_pageBodyV2_text_left__image_right {
                type
                label
                primary {
                  title
                  text
                  image
                  link_title
                  link {
                    ... on PRISMIC_Standard_page {
                      _meta {
                        uid
                        type
                        tags
                        id
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      target
                      _linkType
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_Standard_pageBodyTeam_members {
                type
                label
                primary {
                  title
                }
                fields {
                  job_role
                  name
                  email
                  image
                }
              }
              ... on PRISMIC_Standard_pageBodyWidescreen_image_block {
                type
                fields {
                  link_title
                  link {
                    ... on PRISMIC_Standard_page {
                      title
                      _meta {
                        uid
                        type
                        id
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      target
                      _linkType
                      url
                    }
                    ... on PRISMIC_Contact_page {
                      title
                      _meta {
                        id
                        uid
                        type
                      }
                    }
                  }
                }
                primary {
                  title
                  image
                }
              }
              ... on PRISMIC_Standard_pageBodyImage_slideshow {
                type
                fields {
                  title
                  image
                }
              }
              ... on PRISMIC_Standard_pageBodyTwo_column_text {
                type
                primary {
                  text_first_column
                  text_second_column
                }
              }
              ... on PRISMIC_Standard_pageBody4_image_grid {
                type
                primary {
                  tile1_image
                  tile2_image
                  tile3_image
                  tile4_image
                }
              }
              ... on PRISMIC_Standard_pageBodyTestimonials {
                type
                primary {
                  title
                  caption
                }
                fields {
                  customer_name
                  date
                  testimonial
                }
              }
              ... on PRISMIC_Standard_pageBodyOffers {
                type
                fields {
                  image
                  text
                  title
                  link {
                    ... on PRISMIC_Standard_page {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Basic_page {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_Standard_pageBodyArticles {
                type
                label
                fields {
                  article {
                    _linkType
                    ... on PRISMIC_Article {
                      _meta {
                        uid
                        type
                        id
                      }
                      title
                      text
                      image
                      content_tagging {
                        tag
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_Standard_pageBodyCareer_opportunities_ {
                type
                label
                primary {
                  title
                }
                fields {
                  department_and_location
                  job_description
                  job_title
                  link {
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_Standard_pageBodySearch_block {
                type
                label
                primary {
                  image
                  title
                }
              }
              ... on PRISMIC_Standard_pageBodyVideo {
                type
                primary {
                  video_url
                }
              }
              ... on PRISMIC_Standard_pageBodyIframe_embed {
                type
                primary {
                  url
                }
              }
              ... on PRISMIC_Standard_pageBodyHelp_to_buy_calculator {
                type
                primary {
                  deposit_percentage
                  equity_loan_percentage
                  max_price
                  min_price
                  mortgage_percentage
                  text
                  title
                }
              }
              ... on PRISMIC_Standard_pageBodyImage {
                type
                primary {
                  image
                }
              }
              ... on PRISMIC_Standard_pageBodyUrl_to_iframe {
                type
                primary {
                  url
                }
              }
              ... on PRISMIC_Standard_pageBodyHousing_slideshow {
                type
                label
                fields {
                  housing_development {
                    _linkType
                    ... on PRISMIC_Development {
                      title
                      thumbnail_pre_title
                      thumbnail_image
                      address {
                        postcode
                        town
                      }
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Page;
